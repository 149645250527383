import './App.css';
import './input.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Experiments from './Experiments';
import Homepage from './Homepage';
import Footer from './Footer';
import Header from './Header';
import GettingStarted from './GettingStarted';

export default function Example() {
  return (
    <div className="relative bg-gray-50 overflow-hidden">
      <div className='relative pt-6 pb-16 sm:pb-24'>
        <Header></Header>
        <Router>
          <Routes>
            <Route path="*" element={<Homepage></Homepage>}></Route>
            <Route path="/experiments" element={<Experiments></Experiments>} />
            <Route path="/getting-started" element={<GettingStarted></GettingStarted>} />
          </Routes>
        </Router>
      </div>
      <Footer></Footer>
    </div >
  )
};