const cloudcrunchURL = "https://www.cloudcrunch.in/";

export default function Example() {
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1 text-center">
          &copy; 2022  Hand crafted with 	&#10084; by <a className="cursor" rel="noreferrer" href={cloudcrunchURL} target="_blank">Cloudcrunch Technologies</a>.
        </p>
      </div>
    </footer>
  )
}
