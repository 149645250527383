import WhyAbTesting from './WhyAbTesting';
import Pricing from './Pricing';
import Hero from './Hero';

const Homepage: React.FC = () => <div className="relative bg-gray-50 overflow-hidden">
    <div className="">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <Hero></Hero>
            <div className='mt-32 sm:mx-8'>
                <WhyAbTesting></WhyAbTesting>
            </div>
            <div className='mt-32 sm:mx-8'>
                <Pricing></Pricing>
            </div>
        </main>
    </div>
</div>

export default Homepage;