import React from 'react';

const Hero: React.FC = () => <div className="text-center">
    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl mb-8">
        <span className="block xl:inline">Easiest way to improve user engagement</span>{' '}
        <br></br>
        <span className="block text-indigo-600 xl:inline">by A/B testing ReactJS websites</span>
    </h1>
    <p className="mt-8 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        AyeBeeTesting can help you do it all under 5 minutes.
    </p>
    <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
        <div className="rounded-md shadow">
            <a href="#" className="w-full flex items-center justify-center px-8 py-3 border border-transparent 
        text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                Get started
            </a>
        </div>
        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <a href="#" className="w-full flex items-center justify-center px-8 py-3 border border-transparent 
        text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
            >
                Learn more
            </a>
        </div>
    </div>
</div>

export default Hero;